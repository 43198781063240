<template>
  <th-datatable
    ref="table"
    :headers="headers"
    sortable
    do-route
    show-filter
    force-meta-check
    show-search-filter
    :show-operations="false"
    :resource-limit="100"
    :resource-query="{ query: resourceQuery }"
    resource="vouchers"
    custom-create-path="/loyalty/vouchers/manager/new"
    custom-edit-path="/loyalty/vouchers/manager"
    route-base="/loyalty/vouchers/manager"
    :loading-text="loadingText"
    :locale="locale"
    :buttons="computedButtons"
    :search-filters="filtersList"
    :retry-options="retryOptions()"
    @search-filter-submit="handleSubmit"
    @loading-error="handleLoadingError"
  >
    <template #dropdown-items-start>
      <div>
        <template-file-button :href="templateHref" />
        <voucher-importer
          v-if="$checkPermissions({ scopes: ['loyalty:create'] })"
          :resources="resources"
          @refresh="refresh"
        />
      </div>
    </template>
  </th-datatable>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import * as mem from 'mem'
import compose from 'just-compose'
import VoucherImporter from './components/voucher-importer'
import TemplateFileButton from '@components/importer/template-file-button'
import CurrencyInput from '@/components/inputs/currency-input'
import { parseQueries, makeQuery } from '@/utils/navigation'

export default {
  name: 'VouchersAll',
  metaInfo() {
    return {
      title: this.$t('pages.voucher.title')
    }
  },
  components: {
    VoucherImporter,
    TemplateFileButton
  },
  data() {
    return {
      resources: {},
      templateHref:
        'https://storage.googleapis.com/tillhub-dashboard/templates/vorlage_gutscheinliste.xlsx',
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      timezone: 'Config/getTimeZone'
    }),
    resourceQuery() {
      return {
        deleted: false
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['loyalty:create']
        },
        {
          scopes: ['loyalty:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.old_flow'),
          clickHandler: () => this.$router.push({ name: 'voucher-new-v1' })
        }
      ]
    },
    documentExport() {
      return this.$checkPermissions({ scopes: ['loyalty:export'] })
    },
    headers() {
      return [
        {
          field: 'code',
          label: this.$t('pages.vouchers.all.headers.code'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'system',
          label: this.$t('pages.vouchers.all.headers.system'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: this.findMatchingSystem
        },
        {
          field: 'expires_at',
          label: this.$t('pages.vouchers.all.headers.expires_at'),
          fallback: this.$t('common.forms.labels.forever'),
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            if (!row.expires_at) return this.$t('common.forms.labels.forever')

            //this is a hack to prevent date round up
            const expiresAtDate = this.$date.getStartOfDay(row.expires_at)
            return this.$date.formatDateWithTimezone(
              expiresAtDate,
              this.timezone
            )
          },
          sortType: 'date'
        },
        {
          field: 'amount',
          label: this.$t('pages.vouchers.all.headers.amount'),
          align: 'right',
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            const amount = safeGet(row, 'amount')
            const currency = safeGet(row, 'currency')
            return this.$formatCurrency(amount, currency || null)
          },
          sortType: 'currency'
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'code',
          type: 'input',
          label: this.$t('pages.vouchers.all.headers.code'),
          placeholder: this.$t('common.inputs.placeholders.input')
        },
        {
          name: 'system',
          type: 'resource-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.vouchers.all.headers.system'),
          options: {
            resource: 'voucherSystems',
            options: this.resources.voucher_systems || []
          },
          formatValue: (value) => {
            if (!this.resources.voucher_systems) return value
            return (
              (
                this.resources.voucher_systems.find(
                  (item) => item.id === value
                ) || {}
              ).name || ' - '
            )
          }
        },
        {
          name: 'amount',
          type: 'range',
          component: CurrencyInput,
          label: this.$t('pages.vouchers.all.headers.amount'),
          currency: this.defaultCurrency,
          locale: this.locale,
          min: 0,
          max: 2000,
          formatValue: this.formatCurrencyRange,
          modifyFilter: ({ from, to }) => ({
            amount_from: from,
            amount_to: to
          })
        },
        {
          name: 'expires_at',
          type: 'daterange',
          label: this.$t('common.forms.labels.expires_at'),
          formatValue: (value) => this.$date.formatDateRange(value),
          noFutureDates: false,
          autoClose: false,
          modifyFilter: (filterObject) => ({
            expires_at_start: this.$date.getStartOfDay(filterObject.start),
            expires_at_end: this.$date.getEndOfDay(filterObject.end)
          })
        },
        ...this.switchFilters.map((item) => ({
          ...item,
          label: item.label,
          formatValue: (value) =>
            [undefined, false, 'false'].includes(value)
              ? this.$t('common.interactions.buttons.no')
              : this.$t('common.interactions.buttons.yes')
        }))
      ]
    },
    switchFilters() {
      return [
        {
          name: 'partial_redemption',
          type: 'switch',
          text: {},
          label: this.$t('pages.vouchers.all.headers.partial_redemption')
        },
        {
          name: 'refundable',
          type: 'switch',
          text: {},
          label: this.$t('pages.vouchers.all.headers.refundable')
        },
        {
          name: 'exchange_for_cash',
          type: 'switch',
          text: {},
          label: this.$t('pages.vouchers.all.headers.exchange_for_cash')
        },
        {
          name: 'restriction_single_transaction',
          type: 'switch',
          text: {},
          label: this.$t(
            'pages.vouchers.all.headers.restriction_single_transaction'
          )
        }
      ]
    },
    switchFiltersItems() {
      return this.switchFilters.map(({ name }) => name)
    }
  },
  created() {
    this.fetchResources()
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.vouchers.title')
        })
      })
    },
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch('voucher_systems')
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')
      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    },
    refresh() {
      this.$refs.table.refresh()
    },
    formatCurrencyRange({ from, to }) {
      if (Number.isFinite(+from) && Number.isFinite(+to)) {
        const fromCurrency = this.$formatCurrency(from, this.defaultCurrency)
        const toCurrency = this.$formatCurrency(to, this.defaultCurrency)
        return `${fromCurrency} - ${toCurrency}`
      }
      return null
    },
    handleSubmit(filters) {
      const shouldOmitSwitchFilterItem = (key, value) =>
        [false, 'false'].includes(value) &&
        this.switchFiltersItems.includes(key)
          ? undefined
          : value

      const query = compose(
        parseQueries(shouldOmitSwitchFilterItem),
        makeQuery(this.$route.path)
      )(filters)

      this.$router.push(query)
    },
    findMatchingSystem: mem(
      function (row) {
        if (!row.system) return '--'
        return (
          this.resources?.voucher_systems?.find(
            (system) => system.id === row.system
          )?.name ?? '--'
        )
      },
      { cacheKey: ([row]) => row.system }
    )
  }
}
</script>
