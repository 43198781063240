<template>
  <el-dropdown-item @click.stop="handleImport">
    <svgicon
      :src="require('@/assets/icons/th-icon-upload.svg')"
      :style="{ height: '20px', width: '20px' }"
      class="mr-2 fill-current"
    />
    <span>{{ $t('common.interactions.buttons.import') }}</span>
  </el-dropdown-item>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import pRetry from 'p-retry'
import pLimit from 'p-limit'
import isEmpty from 'just-is-empty'
import get from 'just-safe-get'
import { mapGetters, mapState } from 'vuex'
import { parse } from 'date-fns'
import { isEmptyString } from '@/utils/strings'
import { i18nOverrides } from '@/utils/importer'
import {
  generateDefault,
  attributesToInherit
} from '../../vouchers-manager-v2/helpers'
import { isNullish } from '@/utils/general'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY

const createOptions = (list = []) =>
  list.map(({ id, name }) => ({
    value: id,
    label: name
  }))

export default {
  name: 'VoucherImporter',
  props: {
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    //i18n
    const { t, tm } = useI18n()

    const fields = computed(() => [
      {
        key: 'system',
        label: t('pages.vouchers.edit.form.system.label'),
        type: 'select',
        options: createOptions(props.resources?.voucher_systems),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'code',
        label: t('pages.vouchers.edit.form.code.label'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'amount',
        label: t('common.forms.labels.amount'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'expires_at',
        label: t('pages.vouchers.edit.form.expires_at.label'),
        validators: [
          {
            validate: 'regex_matches',
            regex: `\\s*((?:19|20)\\d{2})\\-(1[012]|0[1-9])\\-(3[01]|[12][0-9]|0[1-9])\\s*`,
            error: t('pages.vouchers.importer.validation.expires_at.error')
          }
        ]
      },
      {
        key: 'comment',
        label: t('pages.vouchers.edit.form.comment.label')
      }
    ])

    const importer = computed(
      () =>
        new FlatfileImporter(FLATFILE_LICENSE_KEY, {
          fields: fields.value,
          type: t('pages.voucher.title'),
          allowInvalidSubmit: false,
          managed: true,
          disableManualInput: true,
          devMode: process.env.NODE_ENV !== 'production',
          styleOverrides: {
            primaryButtonColor: '#279ff6',
            invertedButtonColor: '#7bbaf3'
          },
          i18nOverrides: {
            de: {
              otherLocales: ['de-DE'],
              overrides: i18nOverrides({
                header: tm('pages.vouchers.importer.flatfile.header'),
                header2: tm('pages.vouchers.importer.flatfile.header2')
              })
            },
            en: {
              otherLocales: ['en-US', 'en-GB'],
              overrides: i18nOverrides({
                header: tm('pages.vouchers.importer.flatfile.header'),
                header2: tm('pages.vouchers.importer.flatfile.header2')
              })
            }
          }
        })
    )

    return { importer }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    ...mapState({
      userId: (state) => state.Auth.user || '-',
      orgName: (state) => state.Auth.orgName || '-'
    })
  },
  watch: {
    resources: {
      handler: 'initFlatFileImport'
    }
  },
  methods: {
    async initFlatFileImport() {
      if (isEmpty(this.resources)) return
      this.importer.setCustomer({ userId: this.userId, name: this.orgName })
      this.importer.registerRecordHook(this.validateRecords)
    },
    async handleImport() {
      try {
        const results = await this.importer.requestDataFromUser()
        this.importer.displayLoader()
        await this.createVouchers(results.data)
        this.importer.displaySuccess(
          this.$t('pages.vouchers.importer.messages.success')
        )
        this.$emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          this.$logException(err)
          this.importer.displayError(
            this.$t('pages.vouchers.importer.messages.error')
          )
        }
      }
    },
    async createVouchers(vouchers) {
      const limit = pLimit(10)
      const inst = th.vouchers()
      const ops = this.normalizeVouchers(vouchers)
        .map((voucher) => () => inst.create(voucher))
        .map((fn) => async () => {
          try {
            await pRetry(fn, {
              retries: 3,
              minTimeout: 100,
              maxTimeout: 1000,
              randomize: true
            })
          } catch (err) {
            this.$logException(err)
          }
        })
        .map((fn) => limit(fn))

      try {
        await Promise.allSettled(ops)
      } catch (err) {
        this.$logException(err)
      }
    },
    cleanEmptyStringFields(voucher) {
      let cleanVoucher = { ...voucher }
      // replace empty strings with null
      Object.keys(voucher).forEach((key) => {
        if (isEmptyString(voucher[key])) {
          cleanVoucher[key] = null
        }
      })
      return cleanVoucher
    },
    normalizeVouchers(vouchers) {
      return vouchers.map((voucher) => {
        const cleanVoucher = this.cleanEmptyStringFields(voucher)
        const {
          code,
          expires_at,
          amount,
          system,
          comment,
          ...restVoucher
        } = cleanVoucher

        const normalizedVoucher = {
          ...restVoucher,
          code,
          // Set system
          system,
          // Set static values
          format_type: 'alphanumeric',
          currency: this.defaultCurrency,
          // Default Attributes
          issuable: true,
          partial_redemption: true,
          reissuable: true,
          limited_to_region: false,
          refundable: true,
          exchange_for_cash: false,
          restriction_single_transaction: false,
          is_campaign: false
        }

        // Inherit attributes from the system
        if (system) {
          const attributes = this.getAttributesFromSystem(system)
          attributesToInherit.forEach((attribute) => {
            const newAttr = attributes[attribute]
            if (!isNullish(newAttr)) {
              normalizedVoucher[attribute] = newAttr
            }
          })
        }

        // Was decided this date time format 'yyyy-MM-dd'
        // Could be improved by a more dynamic recognition of the date format
        if (expires_at) {
          // Override system expiration date if provided
          normalizedVoucher.expires_at = parse(
            expires_at,
            'yyyy-MM-dd',
            new Date()
          )
        }

        if (amount) {
          // Override system amount if provided
          normalizedVoucher.amount = parseFloat(amount)
        }

        if (code) {
          // Create the format
          const re = /[^-\s]/g
          // Override system format if code is provided
          normalizedVoucher.format = code.replace(re, 'X')
        }

        if (comment) {
          // Override system comment if provided
          normalizedVoucher.comment = comment
        }

        return normalizedVoucher
      })
    },
    validateRecords(record, index, mode) {
      let out = {}
      // NOTE: for performance it's best to use registerRecordHook only for user changes and not for initial parse, for that use registerFieldHook
      if (mode !== 'change') return out
      if (record.system && !this.findSystem(record.system)) {
        out.system = {
          info: [
            {
              message: this.$t(
                'pages.vouchers.importer.validation.system.error'
              ),
              level: 'error'
            }
          ]
        }
      }
      return out
    },
    findSystem(id) {
      return this.resources?.voucher_systems.find((system) => system.id === id)
        ?.id
    },
    getAttributesFromSystem(system) {
      const voucherSystem = this.resources.voucher_systems?.find(
        ({ id }) => id === system
      )

      const templateAttributes = get(
        voucherSystem,
        'templates.0.attributes',
        {}
      )

      if (!Object.keys(templateAttributes).length) return {}

      return generateDefault(templateAttributes)
    }
  }
}
</script>
