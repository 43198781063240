import get from 'just-safe-get'
import { getDefaultCurrency } from '@/utils/general'

export function generateDefault(template = {}) {
  return {
    active: template.active || true,
    amount_issued_at: null,
    amount: template.amount || null,
    charge_valid_from: template.charge_valid_from || null,
    code: null,
    comment: template.comment || null,
    constraints: template.constraints || genInitialConstrains(),
    currency: template.currency || getDefaultCurrency(),
    exchange_for_cash: get(template, 'exchange_for_cash', false),
    expires_at: null,
    format_type: template.format_type || null,
    format: template.format || null,
    increment_amount_minimum: template.increment_amount_minimum || null,
    is_campaign: template.is_campaign || false,
    partial_redemption: get(template, 'partial_redemption', true),
    redemption_valid_from: template.redemption_valid_from || null,
    refundable: get(template, 'refundable', true),
    regions: template.regions || [],
    restriction_single_transaction: get(
      template,
      'restriction_single_transaction',
      false
    ),
    system: null,
    type: template.type || 'amount',
    validity_period: genValidityPeriod(2)
  }
}

export function genInitialConstrains() {
  return {
    time: {
      scheduled: {
        day_of_week: {
          enabled: false
        }
      }
    }
  }
}

export function genValidityPeriod(value = null) {
  return { unit: 'years', value }
}

export const attributesToInherit = [
  'active',
  'amount',
  'charge_valid_from',
  'comment',
  'constraints',
  'currency',
  'exchange_for_cash',
  'expires_at',
  'format_type',
  'format',
  'increment_amount_minimum',
  'is_campaign',
  'partial_redemption',
  'redemption_valid_from',
  'refundable',
  'regions',
  'restriction_single_transaction',
  'type'
]
